import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import { GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from "@mui/x-data-grid"
import { randomCreatedDate, randomTraderName, randomInt, randomArrayItem } from "@mui/x-data-grid-generator"
import { Margin } from "@mui/icons-material"
import axios from "axios"
import AppContext from "../../AppContext"
import Models from "../model/Models"
import { FormControl } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, useNavigate } from "react-router-dom"
import { useState } from "react"
import Snackbar from '@mui/material/Snackbar';
import { Alert } from "@mui/material"




function EditToolbar(props) {
  const { setRows, setRowModesModel } = props
  const navigate = useNavigate()
  const handleClick = () => {
    // const id = Math.abs(randomInt())
    // setRows(oldRows => [...oldRows, { id, name: "", isNew: true }])
    // setRowModesModel(oldModel => ({
    //   ...oldModel,
    //   [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" }
    // }))
    navigate(`/admin/company/0`)
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" onClick={handleClick}>
        Add Company record
      </Button>
    </GridToolbarContainer>
  )
}

export default function ManageCompanyList() {
  const [roles,setRoles] = useState(JSON.parse(localStorage.getItem("role")))
  const [user,setUser] = useState(JSON.parse(localStorage.getItem("user")))

  // const hasNonSalesRepRole = roles.some(role => {
  //   return typeof role.name === 'string' && role.name !== "sales_rep";
  // });
  const isAdmin = () => {
    return roles.name.includes("user");
  };
  const [rows, setRows] = React.useState([])
  const [rowModesModel, setRowModesModel] = React.useState({})
  const [modelList, setModelList] = React.useState([])
  const [refresh, setRefresh] = React.useState(false)
  const navigate = useNavigate()
  const [open,setOpen] = useState(false)
  const [message,setMessage] = useState()
  const [severity,setSeverity] = useState()
  // const [isAdmin,setIsAdmin] = useState(false)

  const token = JSON.parse(localStorage.getItem("token"))

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })
  let id = state.user.user.id
  let username = state.user.user.name
  let userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  React.useEffect(async () => {
    const uniqueNamesMap = new Map()

    let result = await axios.get(`${AppContext.apiUrl}/api/company`, config)
    //filter out the company list
    result.data.forEach(item => {
      if (!uniqueNamesMap.has(item.name)) {
        uniqueNamesMap.set(item.name, item.id)
      }
    })

    // Convert the Map values to an array
    const uniqueNamesAndIds = Array.from(uniqueNamesMap, ([name, id]) => ({ id, name }))

    setRows(result.data)
  }, [])
  React.useEffect(async () => {
    const uniqueNamesMap = new Map()

    let result = await axios.get(`${AppContext.apiUrl}/api/company`, config)

    result.data.forEach(item => {
      if (!uniqueNamesMap.has(item.name)) {
        uniqueNamesMap.set(item.name, item.id)
      }
    })

    // Convert the Map values to an array
    const uniqueNamesAndIds = Array.from(uniqueNamesMap, ([name, id]) => ({ id, name }))
    
    setRows(result.data)
  }, [refresh])
  
  //call api to get the make list on data load



  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleEditClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = id => () => {
    setRows(rows.filter(row => row.id !== id))
    axios.delete(`${AppContext.apiUrl}/api/company/${id}`, config).then(()=>{
      setOpen(true)
      setSeverity("success")
      setMessage("Company Deleted successfully.")
      
    }
  )
  .catch(e => {
    setOpen(true)
      setSeverity("error")
      setMessage("Something went wrong, please make sure there is no for sale, wanted or any contact that are connecte with this company.")
  })
  }

  const goToDetails = rowSelected =>{
    //alert(rowSelected.id)
    //localStorage.setItem("selectedPipelineDetailId",rowSelected.id)
    if(isAdmin()||user.name===rowSelected.row.assigneeName || rowSelected.row.assigneeName===null || rowSelected.row.assigneeName==='Open'){
    navigate(`/admin/company/${rowSelected.id}`)
    }else{
      setSeverity("warning")
      setMessage("Please note that you don't have permission to view this company details because it's not assigned to you.")
      setOpen(true)
    }
  }

  const handleCancelClick = id => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows.find(row => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter(row => row.id !== id))
    }
  }

  const processRowUpdate = newRow => {
    const updatedRow = { ...newRow, isNew: false }
    setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)))
    console.log(newRow.id, newRow.name, updatedRow.isNew)

    if (newRow.isNew) {
      axios
        .post(`${AppContext.apiUrl}/api/company`, { id: newRow.id, name: newRow.name }, config)
        .then(
          setTimeout(function () {
            // Something you want delayed.
            setRefresh(!refresh)
            navigate(0)
          }, 10000)
        )
        .catch(e => {
          console.log("something happend when editing makes")
        })
    } else {
      axios
        .put(`${AppContext.apiUrl}/api/company/${newRow.id}`, { id: newRow.id, name: newRow.name }, config)
        .then()
        .catch(e => {
          console.log("something happend when editing makes")
        })
    }
    return updatedRow
  }

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel)
  }
  function getTrueKeys(obj) {
    let keys = [];
    if (obj ===null) return null

    // Recursive function to traverse the object
    function traverse(currentObj, prefix = '') {
        for (let key in currentObj) {
            if (currentObj.hasOwnProperty(key)) {
                const fullPath = prefix ? `${prefix}.${key}` : key;
                if (typeof currentObj[key] === 'boolean' && currentObj[key] === true) {
                    keys.push(fullPath); // Add the path to the keys list if it's true
                } else if (typeof currentObj[key] === 'object') {
                    traverse(currentObj[key], fullPath); // Recursively traverse if it's an object
                }
            }
        }
    }

    traverse(obj); // Start the traversal from the root
    return keys.join(', '); // Join all the keys collected with commas
}
  const columns = [
    { field: "name", headerName: "Name", width: 380, editable: true },
    { field: "assigneeName", headerName: "Assignee", width: 100, editable: true },
   // { field: "type", headerName: "type", width: 380, editable: true,valueGetter:(value)=>{getTrueKeys(value)} },
    { field: "profile", headerName: "Company Profile", width: 380, editable: true },
    { field: "state", headerName: "State", width: 80, editable: true },
    { field: "city", headerName: "City", width: 80, editable: true },
    { field: "country", headerName: "Country", width: 80, editable: true },
    { field: "website", headerName: "website", width: 180, editable: true },
    { field: "inventoryLink", headerName: "Inventory Link", width: 180, editable: true },
    // {
    //   field: "model",
    //   headerName: "Model",
    //   width: 420,
    //   editable: true,
    //   type: "singleSelect",
    //   renderCell: params => (
    //     <select
    //       style={{ width: "400px" }}
    //       //value={params.value[0] ? params.value[0].id : ""} // Assuming params.value contains the selected model
    //       value={{ id: params.row.model && params.row.model.length > 0 ? params.row.model[0].id : "", name: params.row.model && params.row.model.length > 0 ? params.row.model[0].name : "" }} // Assuming params.value contains the selected model
    //       onChange={e => {
    //         // Handle the model change here
    //         const newValue = e.target.value
    //         console.log("------params---" + params)
    //         // You may want to call an update function to update your data
    //         // For example: updateModel(params.id, newValue)
    //       }}
    //     >
    //       {params.value &&
    //         params.value.map(modelOption => (
    //           <option key={modelOption ? modelOption.id : ""} id={modelOption ? modelOption.id : ""} value={modelOption ? modelOption.name : ""}>
    //             {modelOption ? modelOption.name : ""}
    //           </option>
    //         ))}
    //     </select>
    //   )
    // },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main"
              }}
              onClick={handleSaveClick(params.id)}
            />,
            <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(params.id)} color="inherit" />
          ]
        }

        if(!isAdmin() && (user.name!=params.row.assigneeName)) return []
        return [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(params.id)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(params.id)} color="inherit" />
        ]
      }
    }
  ]

  return (
    <Box
      sx={{
        display: 'inline-block',
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <Snackbar open={open} autoHideDuration={6000} onClose={()=>setOpen(false)}>
        <Alert onClose={()=>setOpen(false)} severity={severity} sx={{ width: '100%' }}>
        {message}
        </Alert>
      </Snackbar>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="cell"
        onRowClick={goToDetails}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel }
        }}
      />
    </Box>
  )
}
