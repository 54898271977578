import * as React from "react"
import {TextField,Box} from "@mui/material"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import { GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from "@mui/x-data-grid"
import { randomCreatedDate, randomTraderName, randomId, randomArrayItem } from "@mui/x-data-grid-generator"
import { Margin } from "@mui/icons-material"
import axios from "axios"
import AppContext from "../../AppContext"
import Models from "../model/Models"
import { FormControl } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { encodeNumberTo10CharString } from "../../NumberCodec"
import { useState } from "react"

const roles = ["Market", "Finance", "Development"]
const randomRole = () => {
  return randomArrayItem(roles)
}


export default function ContactAdsListMui(props) {
  const [rows, setRows] = React.useState([])
  const [rowModesModel, setRowModesModel] = React.useState({})
  const [makesList, setMakesList] = React.useState([])

  const navigate = useNavigate()


  const token = JSON.parse(localStorage.getItem("token"))

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  let id = state.user.user.id
  let userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  const goToDetails = rowSelected =>{
    //alert(rowSelected.id)
    //localStorage.setItem("selectedPipelineDetailId",rowSelected.id)
  
    navigate(`/editCard/${rowSelected.id}`)
  }
  // const transformInput = input => {
  //   return input.map(item => ({
  //     id: item.id,
  //     categoryName: item.name,
  //     modelName: item.model ? item.model.name : "dummy",
  //     makeName: item.makes ? item.makes.name : "dummy"
  //   }))
  // }

  function arrayToObject(arr) {
    return {
      id: arr.id,
      listingNumber:arr.listingNumber,
      type:arr.type===0?"For Sale":"Wanted",
      title:arr.title,
      make:arr.make?arr.make.name:"",
      model:arr.model?arr.model.name:"",
      category:arr.category?arr.category.name:"",
      price:arr.price
    }
  }
  //call api to get the make list on data load
  React.useEffect( () => {
    let result =  axios.get(`${AppContext.apiUrl}/api/ads/contactAds/${props.contactId}`, config).then(rs=>{
      let temp = rs.data.map(arrayToObject)
      //console.log(temp)
      //if the role is sales_rep, then we need to hide contact if the user id does not match
      
      setRows(temp)
    })

    // setRows(result.data.map(arrayToObject))
  }, [])

  React.useEffect(async () => {
    let result = await axios.get(`${AppContext.apiUrl}/api/ads/contactAds/${props.contactId}`, config)

    setRows(result.data.map(arrayToObject))
  }, [props.contactId])

  const [filters, setFilters] = useState({
    id: '',
    title: '',
    type:'',
    make: '',
    model: '',
    category: '',
    price: '',
  });

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value
    }));
  };

  const filteredRows = rows.filter((row) =>
    Object.keys(filters).every((field) =>
      String(row[field]).toLowerCase().includes(filters[field].toLowerCase())
    )
  );
  
  const columns = [
    { field: "id", headerName: "Id", width: 170},
    { field: "listingNumber", headerName: "listing#", width: 170},
    { field: "type", headerName: "Type", width: 170},
    { field: "title", headerName: "Title", width: 170},
    { field: "make", headerName: "Make", width: 170},
    { field: "model", headerName: "Model", width: 170},
    { field: "category", headerName: "Category", width: 170},
    { field: "price", headerName: "Price", width: 170},
  ]

  
  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
            

      <DataGrid
        rows={rows}
        columns={columns}
        //getRowId={(row) => row.id}
        // editMode="cell"
        // getRowId={(row) => row.id}
        onRowClick={goToDetails}
        // rowModesModel={rowModesModel}
        // onRowModesModelChange={handleRowModesModelChange}
        // onRowEditStop={handleRowEditStop}
        // processRowUpdate={processRowUpdate}
        // slots={{
        //   toolbar: EditToolbar
        // }}
        // slotProps={{
        //   toolbar: { setRows, setRowModesModel }
        // }}
      />
    </Box>
  )
}
