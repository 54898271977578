import React, { useEffect, useState } from "react"
import Select from "react-select"
import axios from "axios"
import { useSelector } from "react-redux"

// const options = [
//   { value: "chocolate", label: "Chocolate" },
//   { value: "strawberry", label: "Strawberry" },
//   { value: "vanilla", label: "Vanilla" }
// ]

function Models({ options, selectedValue, onSelectChange,readOnly }) {
  // const [options, setOptions] = useState()

  // useEffect(async () => {
  //   let result = await axios(`http://localhost:8081/api/makes`)

  //   const category = result.data.map(cat => ({ value: cat.id, label: cat.name }))
  //   //console.log("category " + category)
  //   setOptions(category)
  // }, [])

  const handleSelectChange = event => {
    const value = event
    onSelectChange(value)
  }

  return <Select options={options} value={selectedValue} onChange={handleSelectChange} isDisabled={readOnly}/>
}

export default Models
