import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const DeleteConfirmationModal = ({ show, onHide, onConfirm, deleteReason }) => {
  const [selectedReason, setSelectedReason] = useState("");

  const handleConfirm = () => {
    if (selectedReason) {
      onConfirm(selectedReason);
    }
  };

  return (
    <Dialog 
      open={show} 
      onClose={onHide} 
      fullWidth 
      maxWidth="md"
      PaperProps={{
        sx: {
          width: "50%",
          height: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>Confirm Deletion</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
        <FormControl fullWidth sx={{ width: "80%" }}>
          <InputLabel shrink>Select a reason for deletion</InputLabel>
          <Select
            value={selectedReason}
            onChange={(e) => setSelectedReason(e.target.value)}
            displayEmpty
            fullWidth
            sx={{ minWidth: 250 }}
          >
            <MenuItem disabled value="">
              Select a reason
            </MenuItem>
            {deleteReason.map((reason, index) => (
              <MenuItem key={index} value={reason.value}>{reason.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="error" disabled={!selectedReason}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
