import React from 'react';
// import { DataGrid } from '@mui/x-data-grid';
import { TextField } from '@mui/material';
// import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import { GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from "@mui/x-data-grid"
import { randomCreatedDate, randomTraderName, randomInt, randomArrayItem } from "@mui/x-data-grid-generator"
import { Margin } from "@mui/icons-material"
import axios from "axios"
import AppContext from "../../AppContext"
import Models from "../model/Models"
import { FormControl } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, useNavigate } from "react-router-dom"

const AdsListWithGlobalSearchWanted = ({ searchTerm, onSearchChange, showSearchBox }) => {
  // Sample data
  const [rows, setRows] = React.useState([])
  const navigate = useNavigate()
  //start config
  const token = JSON.parse(localStorage.getItem("token"))

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })
  let id = state.user.user.id
  let userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  //end config

  //start set rows
  function convertAllDatesToYyyyMmDd(allData) {
    return allData.map(item => {
        return {
            ...item,
            createdAt: new Date(item.createdAt).toISOString().split('T')[0]
        };
    });
}

  
  //const pipelines = [{ id: "122222", status: "10%", date_created: "2024-02-02" }]
  //call api to get the pipelines list on data load
  React.useEffect(async () => {
    let result = await axios.get(`${AppContext.apiUrl}/api/admin/ads`, config)
    // const converted = convertAllDatesToYyyyMmDd(result.data)
    setRows(result.data.map(arrayToObject).filter(item=>item.type==='Wanted')
    .sort(((a, b) => new Date(b.createdAt) - new Date(a.createdAt))))
    // const filteredData = converted
    //   .filter(item => item.status !== '0% - no Deal')
    //   .filter(item => item.status !== '100% - Deal')
    //   .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    //   const filteredStatusData = converted
    //   .filter(item => item.status === '0% - no Deal')
    //   .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    //   const filteredStatus100Data = converted
    //   .filter(item => item.status === '100% - Deal')
    //   .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    // if(props.status==="0"){
    //   setRows(filteredStatusData)
    // }else if(props.status==="100"){
    //   setRows(filteredStatus100Data)
    // }else{
    //   setRows(filteredData)
    // }
  }, [searchTerm])

  React.useEffect(async () => {
    
      let result = await axios.get(`${AppContext.apiUrl}/api/admin/ads`, config)
      // const converted = convertAllDatesToYyyyMmDd(result.data)
      setRows(result.data.map(arrayToObject).filter(item=>item.type==='Wanted')
      .sort(((a, b) => new Date(b.createdAt) - new Date(a.createdAt))))
      // const filteredData = converted
      //   .filter(item => item.status !== '0% - no Deal')
      //   .filter(item => item.status !== '100% - Deal')
      //   .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      //   const filteredStatusData = converted
      //   .filter(item => item.status === '0% - no Deal')
      //   .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      //   const filteredStatus100Data = converted
      //   .filter(item => item.status === '100% - Deal')
      //   .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  
      // if(props.status==="0"){
      //   setRows(filteredStatusData)
      // }else if(props.status==="100"){
      //   setRows(filteredStatus100Data)
      // }else{
      //   setRows(filteredData)
      // }
    }, [])
  
  //end set rows

  // Column definitions
  const columns = [
    { field: "id", headerName: "Id", width: 170},
    { field: "listingNumber", headerName: "listing#", width: 170},
    { field: "type", headerName: "Type", width: 170},
    { field: "title", headerName: "Title", width: 170},
    { field: "make", headerName: "Make", width: 170},
    { field: "model", headerName: "Model", width: 170},
    { field: "category", headerName: "Category", width: 170},
    { field: "price", headerName: "Price", width: 170},
    { field: "note", headerName: "Note", width: 170},
    { field: "description", headerName: "Description", width: 170},
    { field: "contact_wanted", headerName: "Contact wanted info", width: 170},
    { field: "contact_forsale", headerName: "Contact forsale", width: 170},
  ]

  // Filtering logic based on the passed searchTerm prop
  const filteredRows = rows?rows.filter((row) =>
    Object.values(row).some((value) =>
      value?value.toString().toLowerCase().includes(searchTerm.toLowerCase()):false
    )
  ):"";

  const goToDetails = rowSelected =>{
    //alert(rowSelected.id)
    localStorage.setItem("selectedPipelineDetailId",rowSelected.id)
  
    window.open(`/editCard/${rowSelected.id}`,"_blank")
  }
  function arrayToObject(arr) {
    return {
      id: arr.id,
      listingNumber:arr.listingNumber,
      type:arr.type===0?"For Sale":"Wanted",
      title:arr.title,
      make:arr.make?arr.make.name:"",
      model:arr.model?arr.model.name:"",
      category:arr.category?arr.category.name:"",
      price:arr.price,
      note:arr.note,
      description:arr.description,
      contact_wanted:arr.contact.wanted,
      contact_forsale:arr.contact.forsale
    }
  }
  return (
    <div style={{ height: 400, width: '100%' }}>
      {/* Conditionally show the search box */}
      {showSearchBox && (
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          style={{ marginBottom: '20px' }}
          value={searchTerm} // Controlled by the prop
          onChange={(e) => onSearchChange(e.target.value)} // Update search term via callback
        />
      )}

      {/* MUI DataGrid */}
      <DataGrid
        rows={filteredRows} // Display filtered rows
        columns={columns}
        pageSize={5}
        onRowClick={goToDetails}
        rowsPerPageOptions={[5]}
      />
    </div>
  );
};

export default AdsListWithGlobalSearchWanted;
