import Navbar from "../navbar/Navbar"
import { useParams } from "react-router-dom"
import React, { useState, useEffect,useMemo } from "react"
import axios from "axios"
import "./company.css"
import {  useSelector } from "react-redux"
import AppContext from "../../AppContext"
import CheckboxGroup from "./CheckBoxGroup"
import { cloneDeep } from "lodash";
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import countryList from "react-select-country-list"
import Select from "react-select"
import { encodeNumberTo10CharString } from "../../NumberCodec"
import Snackbar from '@mui/material/Snackbar';
import { Alert } from "@mui/material"



function CompanyDetails(props) {
  const params = useParams()
  const [data, setData] = useState()
  const [name,setName] = useState()
  const [type,setType] = useState()
  const [companyProfile,setCompanyProfile] = useState()
  const [country,setCountry] = useState()
  const [state_,setState_] = useState()
  const [city,setCity] = useState()
  const [profile,setProfile] = useState()
  const [website,setwebsite] = useState()
  const [inventoryLink,setInventoryLink] = useState()
  const [editedType,setEditedType] = useState()
  const navigate = useNavigate()
  const options = useMemo(() => countryList().getData(), [])
  const [open,setOpen] = useState(false)
  const [message,setMessage] = useState()
  const [severity,setSeverity] = useState()
  const [assigneeId, setAssigneeId] = useState()
  const [assigneeName, setAssigneeName] = useState()
  const [salesRepOption, setSalesRepOption] = useState()
  const [selectedSalesRepOptionValue, setSelectedSalesRepOptionValue] = useState()
  const [roles,setRoles] = useState(JSON.parse(localStorage.getItem("role")))



  const token = JSON.parse(localStorage.getItem("token"))
  
  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  let id = state.user.user.id
  let userToken = state.token
  const companyId = 5
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

useEffect( () => {
  //get sales rep
  axios.get(`${AppContext.apiUrl}/api/user`, config).then(resp => {
    // Filter for users who have the "sales_rep" role
    const salesReps = resp.data.filter(user => user.id!=1)

    // Map the sales_rep role's id and name to { id, label } for selection options
    const salesRepOptions = salesReps.map(user => {
      const salesRepRole = user.roles.find(role => role.name === "sales_rep")
      return {
        value: user.id, // The role's ID
        label: user.name // The user's name
      }
    })
    setSalesRepOption(salesRepOptions)
  })

  //get company
    if(params.companyId != 0){
      const fetchData = async () => {
        const result = await axios(`${AppContext.apiUrl}/api/company/${params.companyId}`,config)
       setData(result.data)
       const typeTmp = result.data.type
       setType(JSON.parse(typeTmp))
       setCountry(options.find(e => e.value === result.data.country))   
       setSelectedSalesRepOptionValue({ value: result.data.assigneeId, label: result.data.assigneeName })
      }
     fetchData()
    }else{
      const data =  {
        name:name,
        type:JSON.stringify(dataOne),
        
        country:"",
        state:state_,
        city:city,
        profile:profile,
        website:website,
        inventoryLink:inventoryLink,
        assigneeId: selectedSalesRepOptionValue ? selectedSalesRepOptionValue.value : 0,
      assigneeName: selectedSalesRepOptionValue ? selectedSalesRepOptionValue.label : ""
      }
      setData(data)
    }
}, [])

  const handleSave = ()=>{
    const data =  {
      name:name,
      type:localStorage.getItem("company_type"),
      
      country:country?country.value:null,
      state:state_,
      city:city,
      profile:profile,
      website:website,
      inventoryLink:inventoryLink,
      assigneeId: selectedSalesRepOptionValue ? selectedSalesRepOptionValue.value : 0,
      assigneeName: selectedSalesRepOptionValue ? selectedSalesRepOptionValue.label : ""
    }
    if(params.companyId != "0"){
      axios
        .put(`${AppContext.apiUrl}/api/company/${params.companyId}`, data, config)
        .then(
          // navigate("/admin/company")
          ()=>{
            setOpen(true)
            setSeverity("success")
            setMessage("Company saved successfully.")
            
          }
        )
        .catch(e => {
          setOpen(true)
            setSeverity("error")
            setMessage("Something went wrong, please contact the admin for help.")
        })
    }else{
      
        axios
          .post(`${AppContext.apiUrl}/api/company`, data, config)
          .then(
            // navigate("/admin/company")
            (res)=>{
              setOpen(true)
              setSeverity("success")
              setMessage("Company created successfully.")
              navigate(`/admin/company/${res.data.id}`)
            }
          )
          .catch(e => {
            setSeverity("error")
            setMessage("Something went wrong, please contact the admin for help.")
            setOpen(true)
          })
    }
  }

  const goToPublicUrl= (contactId)=>{
    window.open(AppContext.adminUrl+"contact/"+encodeNumberTo10CharString(contactId),'_blank')
  }

  /**
   * start of writing 
   * 
   * 
   */

  const safelyParseJson = (jsonString) => {
    try {
        return JSON.parse(jsonString);
    } catch (e) {
        console.error("Failed to parse JSON", e);
        return {}; // Return an empty object or suitable default
    }
};
  const dataOne = {
    End_User: {
      Fab: true,
      Lab: false,
      University:false
    },
    Refurbisher: false,
    Inventory_dealer:false,
    Broker:false,
    Logistics: {
      "De_Install": false,
      Removal: false,
      Packing_Crating:false,
      Customer_Clearence:false,
      Forwarder:false
    },
    OEM:false,
    Lease_Rental:false,
    Auctioneer:false
  }
  
  
  const transform = (data, parent) =>  {
      return Object.keys(data).map((key) => {
          const value = data[key];
          const node = {
              label: key,
              checked: false,
              childrenNodes: [],
              parent: parent,
          };
  
          if (typeof value === "boolean") {
              node.checked = value;
          } else {
              const children = transform(value, node);
              node.childrenNodes = children;
              if (children.every((node) => node.checked)) {
                  node.checked = true;
              }
          }
  
          return node;
      });
  }

  const reverseTransform = (nodes) => {
    const result = {};
    nodes.forEach(node => {
        if (node.childrenNodes.length > 0) {
            // If there are children, recursively reverse transform
            result[node.label] = reverseTransform(node.childrenNodes);
        } else {
            // If no children, use the `checked` value directly
            result[node.label] = node.checked;
        }
    });
    return result;
}

  
  
  const updateAncestors = (node) => {
      if (!node.parent) {
          return;
      }
  
      const parent = node.parent;
      if (parent.checked && !node.checked) {
          parent.checked = false;
          updateAncestors(parent);
          return;
      }
  
      if (!parent.checked && node.checked) {
          if (parent.childrenNodes.every((node) => node.checked)) {
              parent.checked = true;
              updateAncestors(parent);
              return;
          }
      }
  
      return;
  }
  
  const toggleDescendants = (node) => {
      const checked = node.checked;
  
      node.childrenNodes.forEach((node) => {
          node.checked = checked;
          toggleDescendants(node);
      })
  }
  
  const findNode = (nodes, label, ancestors) => {
      let node = undefined;
      if (ancestors.length === 0) {
          return nodes.filter((node) => node.label === label)[0];
      }
  
      for (let ancestor of ancestors) {
          const candidates = node ? node.childrenNodes : nodes;
          node = candidates.filter((node) => node.label === ancestor)[0];
      }
      return node?.childrenNodes.filter((node) => node.label === label)[0];
  }
  
  const NestedCheckbox = ({ data }) => {
      const initialNodes = transform(data);
      const [ nodes, setNodes ] = useState(initialNodes);
  
      const handleBoxChecked = (e, ancestors) => {
          const checked = e.currentTarget.checked;
          const node = findNode(nodes, e.currentTarget.value, ancestors);
  
          node.checked = checked;
          toggleDescendants(node);
          updateAncestors(node);
  
          setNodes(cloneDeep(nodes));
      }
  useEffect(()=>{
        // console.log(nodes)
         console.log(JSON.stringify(reverseTransform(nodes)))
         //setEditedType(nodes)
        //setType(JSON.stringify(reverseTransform(nodes)))
        localStorage.setItem("company_type",JSON.stringify(reverseTransform(nodes)))
        //setType(JSON.parse(localStorage.getItem("company_type")))
        // console.log(JSON.parse("{\"End_User\":{\"Fab\":true,\"Lab\":false,\"University\":true},\"Refurbisher\":false,\"Inventory_dealer\":false,\"Broker\":true,\"Logistics\":{\"De_Install\":true,\"Removal\":false,\"Packing_Crating\":true,\"Customer_Clearence\":true,\"Forwarder\":true}}"))
      },[nodes])
  
      return (
        <>
          <NestedCheckboxHelper nodes={nodes}  ancestors={[]} onBoxChecked={handleBoxChecked}/>
          </>
      );
  }
  
  function NestedCheckboxHelper({ nodes, prefix = 'root', ancestors = [], onBoxChecked }) {
    return (
      <div className="checkbox-group">
        {
          nodes.map(({ label, checked, childrenNodes }) => {
            const id = `${prefix}.${label}`;
            let children = null;
            if (childrenNodes.length > 0) {
              children = (
                <NestedCheckboxHelper
                  nodes={childrenNodes}
                  prefix={id} // Updated prefix for deeper nesting
                  ancestors={[...ancestors, label]}
                  onBoxChecked={onBoxChecked}
                />
              );
            }
  
            return (
              <div className="checkbox-item" key={id}>
                <input type="checkbox" name={id} value={label} checked={checked} onChange={(e) => onBoxChecked(e, ancestors)}/>
                <label style={{marginBottom:"1px",marginTop:"1px"}} htmlFor={id}>{label}</label>
                {children}
              </div>
            );
          })
        }
      </div>
    );
  }
  /** end of check box group */
  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={()=>setOpen(false)}>
        <Alert onClose={()=>setOpen(false)} severity={severity} sx={{ width: '100%' }}>
        {message}
        </Alert>
      </Snackbar>
      <Navbar />
      {data && (
        
        <div style={{marginLeft:"5%"}}>
        <div style={{textAlign:"center",fontWeight:"bolder"}}><h3>Company details page</h3></div>
        {/* <CheckboxGroup /> */}
       
  <div><label>Name:</label></div>
        <div style={{padding: "0 24px",
  marginLeft: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems:"left"}}><input type="text"  defaultValue={data.name} onChange={e=>setName(e.target.value)}></input></div>
        <div><label>Company Profile:</label></div>
        {/* companyProfile:companyProfile,
      country:country,
      state:state_,
      city:city,
      profile:profile,
      website:website,
      inventoryLink:inventoryLink */}
        <div style={{padding: "0 24px",
  marginLeft: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems:"left"}}><textarea rows="10" cols="300" type="text"  defaultValue={data.profile} onChange={e=>setProfile(e.target.value)}></textarea></div>
  <div>
            <label>country</label>
          </div>
          <Select options={options} value={country} onChange={e=>setCountry(e)} />
  <div><label>state:</label></div>
        <div style={{padding: "0 24px",
  marginLeft: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems:"left"}}><input type="text"  defaultValue={data.state} onChange={e=>setState_(e.target.value)}></input></div>
  <div><label>city:</label></div>
        <div style={{padding: "0 24px",
  marginLeft: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems:"left"}}><input type="text"  defaultValue={data.city} onChange={e=>setCity(e.target.value)}></input></div>
  
  <div><label>website:</label></div>
        <div style={{padding: "0 24px",
  marginLeft: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems:"left"}}><input type="text"  value={data.website} onChange={e=>setwebsite(e.target.value)}></input></div>
  <div><label>inventoryLink:</label></div>
        <div style={{padding: "0 24px",
  marginLeft: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems:"left"}}><input type="text"  value={data.inventoryLink} onChange={e=>setInventoryLink(e.target.value)}></input></div>
 <div><label>Company Type:</label></div>
        <div style={{padding: "0 24px",
  marginLeft: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems:"left"}}>{data ?<NestedCheckbox data={JSON.parse(data.type || '{}')} ancestors={[]}/>:"loading"}</div>
  {roles &&roles.name && roles.name[0]!="sales_rep" &&(
            <>
            <div>
              <label>Account Manager:</label>
            </div>
            <Select options={salesRepOption} value={selectedSalesRepOptionValue} onChange={e => setSelectedSalesRepOptionValue(e)} />
            </>
            )}
<div><Button onClick={handleSave}>Save</Button></div>
        <hr/>
        {params.companyId!="0" && (
        <div><Button onClick={()=>window.open(`/admin/contact/0?companyId=${params.companyId}`,'_blank')}>Create New Contact</Button></div>
        )}
        <div><label>ContactList:</label></div>
        <div style={{padding: "0 24px",
  marginLeft: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems:"left",
  marginBottom:"20px"}}>
    <table style={{width:"100%"}}><tr><td><b>first name</b></td><td><b>last name</b></td>
  <td><b>Note</b></td><td><b>Connection note</b></td>
  <td><b>Title</b></td><td><b>Cell phone</b></td>
  <td><b>priority</b></td><td><b>Next step</b></td>
  <td><b>wanted</b></td><td><b>for sale</b></td>
  <td><b>Way We Communicate</b></td>
  </tr>
    {data && data.contact&& data.contact.map(item=>(
      <tr  onClick={()=>goToPublicUrl(item.id)} style={{textDecoration:"underLine",cursor:"pointer"}} ><td>{item.firstName}</td><td>{item.lastName}</td>
      <td>{item.note}</td><td>{item.connectionNote}</td>
      <td>{item.title}</td><td>{item.cellPhone}</td>
      <td>{item.priority}</td><td>{item.nextStep}</td>
      <td>{item.wanted}</td><td>{item.forSale}</td>
      <td>{item.wayWeCommunicate}</td>
      </tr>
      ))}
    </table>
  </div>

        </div>
        
        
      )}
    </div>
  )
}

export default CompanyDetails
