import React, { useEffect, useState } from "react"
import Select from "react-select"
import axios from "axios"
import { useSelector } from "react-redux"
import AppContext from "../../AppContext"
// const options = [
//   { value: "chocolate", label: "Chocolate" },
//   { value: "strawberry", label: "Strawberry" },
//   { value: "vanilla", label: "Vanilla" }
// ]

export default function Category({ options_, selectedValue, onSelectChange,readOnly }) {
  const [options, setOptions] = useState()

  function uniqueByName(data, key) {
    return [...new Map(data.map(x => [key(x), x])).values()]
  }
  useEffect(async () => {
    let result = await axios(`${AppContext.apiUrl}/api/category`)
    const categories = uniqueByName(result.data, it => it.name)
    // console.log("----------------" + JSON.stringify(uniqueByName(result.data, it => it.name)))

    const category = categories.map(cat => ({ value: cat.id, label: cat.name }))
    //console.log("category " + category)
    setOptions(category)
  }, [])

  const handleSelectChange = event => {
    const value = event
    onSelectChange(value)
  }

  return <Select placeholder="select a category" options={options} value={selectedValue} onChange={handleSelectChange} isDisabled={readOnly}/>
}
