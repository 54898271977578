import { Link, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { removeUser } from "../../reducers/user/action"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../home/home.css"
import axios from "axios"
import { Stack } from "@mui/material"
import { Autocomplete, Box, Button, Checkbox, Collapse, Divider, FormControl, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField } from "@mui/material"
import { Add, Remove } from "@mui/icons-material"
import MUIDataTable from "mui-datatables"
import { Avatar } from "@material-ui/core"
import AppContext from "../../AppContext"
import { FormGroup, FormLabel, FormControlLabel, Grid } from "@mui/material"

import Menu from "@mui/material/Menu"
import MakeList from "../productMakes/MakeList"
import Navbar from "../navbar/Navbar"
import { useParams } from "react-router-dom"
import { Tabs, Tab } from '@mui/material';
import ManageEmailDraftList from "./manageEmailDraftList"
import ManageEmailSentList from "./manageEmailSentList"


function MainEmailDraftList() {
  //start search filter
  //for make filter

  //tab
  const [value, setValue] = useState(0);

  //end of tab
  const params = useParams()
  const [filterText, setFilterText] = useState("")
  const filters = [
    {
      label: "Make",
      type: "dropdown",
      options: ["3D PLUS", "3M", "AB SCIEX / APPLIED BIOSYSTEMS / ABI / MDS SCIEX"]
    },
    {
      label: "Model",
      type: "autocomplete",
      options: ["PI 9500", "9500xR", "xR80", "Quantum", "Quantum Leap"]
    },
    {
      label: "Category",
      type: "autocomplete",
      options: ["Ion Implanters"]
    }
    // {
    //   label: "Screen size",
    //   type: "checkbox",
    //   options: ["13in", "14in", "15.6in", "Over 15.6in"]
    // },
    // {
    //   label: "Price",
    //   type: "checkbox",
    //   options: ["< $1000", "$1000-$1400", "$1400-$2000", "> $2000"],
    //   required: true
    // },
    // {
    //   label: "Frame refresh rate",
    //   type: "checkbox",
    //   options: ["61-99 Hz", "100-120 Hz", "121-144 Hz", "145-240 Hz"]
    // },
    // {
    //   label: "RAM",
    //   type: "checkbox",
    //   options: ["< 2GB", "2-4 GB", "4-8 GB", "8-16 GB"]
    // }
  ]

  const [openFilters, setOpenFilters] = React.useState({})
  const [filterValues, setFilterValues] = React.useState({})

  const handleToggleFilter = filterLabel => {
    setOpenFilters(prevOpenFilters => ({
      ...prevOpenFilters,
      [filterLabel]: !prevOpenFilters[filterLabel]
    }))
  }

  const handleFilterChange = event => {
    setFilterValues(prevFilterValues => ({
      ...prevFilterValues,
      [event.target.name]: event.target.value
    }))
  }

  const handleAutocompleteChange = (event, value, filterLabel) => {
    setFilterValues(prevFilterValues => ({
      ...prevFilterValues,
      [filterLabel]: value
    }))
  }

  const handleCheckboxChange = event => {
    setFilterValues(prevFilterValues => ({
      ...prevFilterValues,
      [event.target.name]: event.target.checked ? [...(prevFilterValues[event.target.name] || []), event.target.value] : prevFilterValues[event.target.name].filter(value => value !== event.target.value)
    }))
  }

  const handleResetAll = () => {
    setOpenFilters({})
    setFilterValues({})
  }

  useEffect(() => {
    console.log("filter value:", filterValues)
    console.log("filter value price:", filterValues["Price"])

    //   products.filter((product) =>
    //   product.category.toLowerCase().includes(filter.toLowerCase())
    // )
    data.filter(ads => ads.ca)
  }, [filterValues])
  //end

  const [data, setData] = useState([])
  const [keyword, setKeyword] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const state = useSelector(state => {
    return {
      user: state.userReducer,
      token: state.userReducer.token
    }
  })

  const id = state.user.user.id
  const userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  const searchKeyword = e => {
    setKeyword(e.target.value)
  }

  function transformArray(arr) {
    return arr.map(item => (typeof item === "object" && item !== null && "name" in item ? item.name : item))
  }

  const search = e => {
    e.preventDefault()

    // axios
    //   .get(`http://localhost:8081/api/ads/search/${keyword}`)
    //   .then(response => {
    //     setData(response.data)
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   })
  }

  

  const goToMake = e => {
    e.preventDefault()
    navigate("/admin/make")
  }
  

  const logout = e => {
    const action = removeUser()
    dispatch(action)
    navigate("/")
  }

  {
    /* test start */
  }

  //start tab

const TabPanel = ({ children, value, index }) => (
  <div hidden={value !== index} role="tabpanel">
    {value === index && children}
  </div>
);
  //end of tab

  return (
    <div>
      <Navbar />

      <div className="search">
        {/* <form className="example">
          <input type="text" placeholder="..something in mind? " onChange={searchKeyword} />
          <button>
            <i className="bi bi-search" onClick={search}></i>
          </button>
        </form> */}
      </div>
      

      
      <div>
      <Tabs value={value} onChange={(e, newValue) => setValue(newValue)}>
        <Tab label="Email Draft List" />
        <Tab label="Email match sent List" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ManageEmailDraftList status={params.status }/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ManageEmailSentList />
      </TabPanel>
    </div>
    </div>
  )
}

export default MainEmailDraftList
