import { Button } from "react-bootstrap"
import { icon } from "bootstrap"
import Navbar from "../navbar/Navbar"
import Singin from "../singup/Singup"
import "bootstrap-icons/font/bootstrap-icons.css"
import { Link, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import "./login.css"
import jwt_decode from "jwt-decode"
import { addUser, addToken, addRole } from "../../reducers/user/action"
import AppContext from "../../AppContext"

function Login() {
  const [name, setName] = useState()
  const [password, setPassword] = useState()
  const navigate = useNavigate()
  let result = false

  const dispatch = useDispatch()
  const state = useSelector(state => {
    return {
      user: state.userReducer //state.user.user,
    }
  })

  const userName = e => {
    setName(e.target.value)
  }

  const userPassword = e => {
    setPassword(e.target.value)
  }

  const verification = e => {
    e.preventDefault()
    const data = {
      name,
      password
    }

    axios
      .post(`${AppContext.apiUrl}/api/login`, data)
      .then(response => {
        console.log(response.data)
        const token = response.data.access_token
        localStorage.setItem("token", JSON.stringify(token))
        const decoded = jwt_decode(token)

        const user_action = addUser({
          id: decoded.id,
          name: decoded.sub
        })

        const role_action = addRole({
          id: decoded.id,
          name: decoded.roles
        })

        const token_action = addToken(token)

        dispatch(user_action)
        dispatch(token_action)
        dispatch(role_action)

        if(decoded.roles && decoded.roles[0]==="sales_rep"){
          navigate("/admin/sales")
        }else{
          navigate("/admin")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <div>
      <Navbar />
      <div className="login-wrapper fadeInDown">
        <div id="login-formContent">
          <h2 className="login-active">Login</h2>
          <form>
            <input type="text" placeholder="username" onChange={userName} />
            <input id="input-pass" type="password" placeholder="password" onChange={userPassword} />
            <div id="error-msg-holder">
              <p className="error-msg">Incorrect username/password</p>
            </div>
            <button type="submit" className="button" onClick={verification}>
              Login
            </button>
          </form>
          {/* <div id="login-formFooter">
            <Link to="/singup">
              <p className="login-underlineHover">Sign up</p>
            </Link>
            <br></br>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Login
