import Navbar from "../navbar/Navbar"
import { useParams } from "react-router-dom"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { useSelector } from "react-redux"
import AppContext from "../../AppContext"
import { decodeStringToNumber, encodeNumberTo10CharString } from "../../NumberCodec"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import CompanyList from "../company/CompanyList"
import ContactAdsListMui from "../ads/ContactAdsListMui"
import Select from "react-select"
import Snackbar from "@mui/material/Snackbar"
import { Alert } from "@mui/material"
import { useLocation } from "react-router-dom"
import CustomExpandableList from "../customList/CustomExpandableList"
import CustomizedListItem from "../customList/CustomExpandableList"
import ContactEmailListMui from "../customList/ContactEmailList"

// "priority": null,
// "nextStep": null,
// "firstName": null,
// "lastName": null,
// "originalName": null,
// "title": null,
// "cellPhone": null,
// "wayWeCommunicate": null,
// "wanted": null,
// "forSale": null,
// "note": null,
// "connectionNote": null,
// "accountManager": null

function ContactDetails(props) {
  const params = useParams()
  const [data, setData] = useState()
  const [priority, setPriority] = useState()
  const [nextStep, setNextStep] = useState()
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [originalName, setOriginalName] = useState()
  const [title, setTitle] = useState()
  const [cellPhone, setCellPhone] = useState()
  const [wayWeCommunicate, setWayWeCommunicate] = useState()
  const [wanted, setWanted] = useState()
  const [forSale, setForSale] = useState()
  const [note, setNote] = useState()
  const [connectionNote, setConnectionNote] = useState()
  const [accountManager, setAccountManager] = useState()
  const [contactEmail, setContactEmail] = useState()
  const [contactEmail2, setContactEmail2] = useState()
  const [contactEmail3, setContactEmail3] = useState()

  const [accountManagerName, setAccountManagerName] = useState()
  const [companyId, setCompanyId] = useState()
  const [companyName, setCompanyName] = useState()

  const [makesOptions, setMakesOptions] = useState([])
  const [selectedMakeValue, setSelectedMakeValue] = useState()

  const [managerOptions, setManagerOptions] = useState()
  const [selectedManagerValue, setSelectedManagerValue] = useState()
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState()
  const [severity, setSeverity] = useState()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  // Get specific query parameters
  const companyIdFromParams = queryParams.get("companyId")
  const [refresh, setRefresh] = useState(false)
  const [assigneeId, setAssigneeId] = useState()
  const [assigneeName, setAssigneeName] = useState()
  const [salesRepOption, setSalesRepOption] = useState()
  const [selectedSalesRepOptionValue, setSelectedSalesRepOptionValue] = useState()
  const [roles,setRoles] = useState(JSON.parse(localStorage.getItem("role")))

  const navigate = useNavigate()

  const token = JSON.parse(localStorage.getItem("token"))

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  let id = state.user.user.id
  let userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  useEffect(async () => {
    //get sales repos option
    axios.get(`${AppContext.apiUrl}/api/user`, config).then(resp => {
      // Filter for users who have the "sales_rep" role
      const salesReps = resp.data.filter(user => user.roles.some(role => role.name === "sales_rep"))

      // Map the sales_rep role's id and name to { id, label } for selection options
      const salesRepOptions = salesReps.map(user => {
        const salesRepRole = user.roles.find(role => role.name === "sales_rep")
        return {
          value: user.id, // The role's ID
          label: user.name // The user's name
        }
      })
      setSalesRepOption(salesRepOptions)
    })

    //get company
    await axios(`${AppContext.apiUrl}/api/company`, config).then(result => {
      const makes = result.data.map(cat => ({ value: cat.id, label: cat.name }))
      //console.log("category " + category)
      setMakesOptions(makes)
      if (companyIdFromParams) {
        setSelectedMakeValue(makes.find(option => option.value === (companyIdFromParams ? companyIdFromParams.replace('"', "") : null)))
      } else {
        if (params.contactId != "0") {
          const result = axios(`${AppContext.apiUrl}/api/company/getByContactId/${decodeStringToNumber(params.contactId)}`, config).then(res => {
            setCompanyId(res.data.id)
            setCompanyName(res.data.name)
            setSelectedMakeValue({ value: res.data.id, label: res.data.name })
          })
        }
        //setSelectedMakeValue(makes.find(option => option.value === companyId))
      }
    })
  }, [])
  useEffect(async () => {
    if (params.contactId != "0") {
      const result = await axios(`${AppContext.apiUrl}/api/contact/${decodeStringToNumber(params.contactId)}`, config)

      setData(result.data)
      setFirstName(result.data.firstName)
      setLastName(result.data.lastName)
      setPriority(result.data.priority)
      setNextStep(result.data.nextStep)
      setOriginalName(result.data.originalName)
      setTitle(result.data.title)
      setCellPhone(result.data.cellPhone)
      setWayWeCommunicate(result.data.wayWeCommunicate)
      setWanted(result.data.wanted)
      setForSale(result.data.forSale)
      setNote(result.data.note)
      setConnectionNote(result.data.connectionNote)
      setSelectedManagerValue({ value: result.data.accountManagerName, label: result.data.accountManagerName })
      setContactEmail(result.data.contactEmail)
      setContactEmail2(result.data.contactEmail2)
      setContactEmail3(result.data.contactEmail3)
      setNextStep(result.data.nextStep)
      // setSelectedMakeValue({value:result.data.companyId,label:result.data.companyName})
    } else {
      const data = {
        firstName: firstName,
        lastName: lastName,
        priority: priority,
        nextStep: nextStep,
        originalName: originalName,
        title: title,
        cellPhone: cellPhone,
        wayWeCommunicate: wayWeCommunicate,
        wanted: wanted,
        forSale: forSale,
        note: note,
        connectionNote: connectionNote,
        accountManagerName: selectedManagerValue ? selectedManagerValue.value : null,
        contactEmail: contactEmail,
        contactEmail2: contactEmail2,
        contactEmail3: contactEmail3
      }
      setData(data)
    }
  }, [])

  const managersOptions = [
    { value: "Masa", label: "Masa" },
    { value: "Vivian", label: "Vivian" },
    { value: "Alan", label: "Alan" },
    { value: "Jurin", label: "Jurin" },
    { value: "Open", label: "Open" }
  ]

  useEffect(() => {
    setManagerOptions(managersOptions)
    // Set the default status to 'Masa'
    if (params.contactId === "0") {
      setSelectedManagerValue(managersOptions[0])
    }
  }, [])

  useEffect(async () => {
    if (params.contactId != "0") {
      const result = await axios(`${AppContext.apiUrl}/api/company/getByContactId/${decodeStringToNumber(params.contactId)}`, config)
      setCompanyId(result.data.id)
      setCompanyName(result.data.name)
      setSelectedMakeValue({ value: result.data.id, label: result.data.name })
      setSelectedSalesRepOptionValue({ value: result.data.assigneeId, label: result.data.assigneeName })
    }
  }, [])

  useEffect(async () => {
    if (params.contactId != "0") {
      const result = await axios(`${AppContext.apiUrl}/api/company/getByContactId/${decodeStringToNumber(params.contactId)}`, config)
      setCompanyId(result.data.id)
      setCompanyName(result.data.name)
      setSelectedMakeValue({ value: result.data.id, label: result.data.name })
      setSelectedSalesRepOptionValue({ value: result.data.assigneeId, label: result.data.assigneeName })
    }
  }, [refresh])

  useEffect(() => {
    setCompanyId(selectedMakeValue ? selectedMakeValue.value : null)
  }, [selectedMakeValue])
  const handleSave = e => {
    //e.preventDefault()
    if (!selectedMakeValue) {
      if (!companyIdFromParams) {
        setMessage("Company is required to create contact!")
        setSeverity("error")
        setOpen(true)
        return
      }
    }
    const data = {
      firstName: firstName,
      lastName: lastName,
      priority: priority,
      nextStep: nextStep,
      originalName: originalName,
      title: title,
      cellPhone: cellPhone,
      wayWeCommunicate: wayWeCommunicate,
      wanted: wanted,
      forSale: forSale,
      note: note,
      connectionNote: connectionNote,
      accountManagerName: selectedManagerValue ? selectedManagerValue.value : null,
      contactEmail: contactEmail,
      contactEmail2: contactEmail2,
      contactEmail3: contactEmail3,
      nextStep: nextStep,
      company: { id: companyId },
      assigneeId: selectedSalesRepOptionValue ? selectedSalesRepOptionValue.value : 0,
      assigneeName: selectedSalesRepOptionValue ? selectedSalesRepOptionValue.label : ""
      // "priority": null,
      // "nextStep": null,
      // "firstName": null,
      // "lastName": null,
      // "originalName": null,
      // "title": null,
      // "cellPhone": null,
      // "wayWeCommunicate": null,
      // "wanted": null,
      // "forSale": null,
      // "note": null,
      // "connectionNote": null,
      // "accountManager": null
    }
    if (params.contactId != "0") {
      axios
        .put(`${AppContext.apiUrl}/api/contact/${decodeStringToNumber(params.contactId)}/${companyId}`, data, config)
        .then(() => {
          setOpen(true)
          setSeverity("success")
          setMessage("Contact saved successfully.")
          setRefresh(!refresh)
        })
        .catch(e => {
          console.log("something happend when editing contact")
          setOpen(true)
          setSeverity("error")
          setMessage("Something went wrong, please contact the admin for help")
        })
    } else {
      axios
        .post(`${AppContext.apiUrl}/api/company/${companyIdFromParams ? companyIdFromParams : companyId}/contact`, data, config)
        .then(
          //navigate("/admin/contact")
          res => {
            setOpen(true)
            setSeverity("success")
            setMessage("Contact created successfully.")
            navigate(`/admin/contact/${encodeNumberTo10CharString(res.data.id)}`)
            setRefresh(!refresh)
          }
        )
        .catch(e => {
          console.log("something happend when editing contact")
          setOpen(true)
          setSeverity("error")
          setMessage("Something went wrong, please contact the admin for help")
        })
    }
  }

  const createForSaleUrl = () => {
    window.open(`/addForSaleFromContact/${companyId}/${decodeStringToNumber(params.contactId)}`, "_blank")
  }
  const createWantedUrl = () => {
    window.open(`/addWantedFromContact/${companyId}/${decodeStringToNumber(params.contactId)}`, "_blank")
  }

  const handleMassUpload = () => {
    window.open(`/massUploadForSaleFromContact/${companyId}/${decodeStringToNumber(params.contactId)}`, "_blank")
  }

  //email start
  const docs =
    // data.documents  //this coming from a json file
    {
      documents: [
        {
          Id: 1,
          Name: "Category 1",
          Sheets: [
            {
              Id: 1,
              Title: "Title1 "
            },
            {
              Id: 2,
              Title: "Title 2"
            },
            {
              Id: 3,
              Title: "Title 3"
            }
          ]
        }
      ]
    }
  //email end

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
        <Alert onClose={() => setOpen(false)} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Navbar />
      {data && (
        <div className="ads-from">
          <div style={{ textAlign: "center", fontWeight: "bolder" }}>
            <h3>Contact details page</h3>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ width: "30%" }}>
              <b>First Name</b>:<input type="text" defaultValue={firstName} onChange={e => setFirstName(e.target.value)}></input>
            </div>
            <div style={{ width: "30%" }}>
              <b>Last Name</b>: <input type="text" defaultValue={lastName} onChange={e => setLastName(e.target.value)}></input>
            </div>
            <div style={{ width: "30%" }}>
              <b>Original Name</b>:<input type="text" defaultValue={originalName} onChange={e => setOriginalName(e.target.value)}></input>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ width: "30%" }}>
              <b>Title:</b>
              <input type="text" defaultValue={title} onChange={e => setTitle(e.target.value)}></input>
            </div>
            <div style={{ width: "30%" }}>
              {" "}
              <b>Priority:</b>
              <input type="text" defaultValue={priority} onChange={e => setPriority(e.target.value)}></input>
            </div>
            <div style={{ width: "30%" }}>
              <b>Cell phone:</b> <input type="text" defaultValue={cellPhone} onChange={e => setCellPhone(e.target.value)}></input>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ width: "30%" }}>
              <b>Email1:</b>
              <input type="text" defaultValue={contactEmail} onChange={e => setContactEmail(e.target.value)}></input>
            </div>
            <div style={{ width: "30%" }}>
              <b>Email2:</b>
              <input type="text" defaultValue={contactEmail2} onChange={e => setContactEmail2(e.target.value)}></input>
            </div>
            <div style={{ width: "30%" }}>
              <b>Email3:</b>
              <input type="text" defaultValue={contactEmail3} onChange={e => setContactEmail3(e.target.value)}></input>
            </div>
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
          <div style={{ width: "30%" }}>
            <b>Note:</b><input type="text" defaultValue={note} onChange={e => setNote(e.target.value)}></input>
          </div>
          
        

          {companyIdFromParams === null && (
            <>
              <div style={{ width: "30%" }}>
                
                <b>Company Name:</b><CompanyList options={makesOptions} selectedValue={selectedMakeValue} onSelectChange={setSelectedMakeValue} />
              </div>
            </>
          )}
          </div>
          
          <div>
            <b>The way we Communicate:</b>
            <input type="text" defaultValue={wayWeCommunicate} onChange={e => setWayWeCommunicate(e.target.value)}></input>
          </div>
          <div>
            <label>Wanted:</label>
          </div>
          <div style={{ padding: "0 24px", marginLeft: "10px", display: "flex", justifyContent: "left", alignItems: "left" }}>
            <textarea rows="10" cols="300" defaultValue={wanted} onChange={e => setWanted(e.target.value)} />
          </div>
          <div>
            <label>For Sale:</label>
          </div>
          <div style={{ padding: "0 24px", marginLeft: "10px", display: "flex", justifyContent: "left", alignItems: "left" }}>
            <textarea rows="10" cols="300" defaultValue={forSale} onChange={e => setForSale(e.target.value)} />
          </div>

          <div>
            <label>Connection Note:</label>
          </div>
          <div style={{ padding: "0 24px", marginLeft: "10px", display: "flex", justifyContent: "left", alignItems: "left" }}>
            <input type="text" defaultValue={connectionNote} onChange={e => setConnectionNote(e.target.value)}></input>
          </div>

          
          <div>
            <Button onClick={handleSave}>Save</Button>
          </div>
          <div>
            <Button onClick={handleMassUpload}>Mass upload</Button>
          </div>
          <hr />
          <div>
            <a onClick={createForSaleUrl} style={{ textDecoration: "underLine", cursor: "pointer" }}>
              Create For Sale under this contact
            </a>
          </div>
          <div>
            <a onClick={createWantedUrl} style={{ textDecoration: "underLine", cursor: "pointer" }}>
              Create Wanted under this contact
            </a>
          </div>
          <hr />
          <div>
            <label>All listing:</label>
          </div>
          <div>
            {/* test start */}
            <ContactAdsListMui contactId={decodeStringToNumber(params.contactId)} />
            {/* <MUIDataTable title={""} data={data} columns={columns} options={options} /> */}
            <p></p>
            {/* test end */}
          </div>
          <hr />
          <div>
            <label>Email Communications:</label>
          </div>
          <div>
            {contactEmail + ":"}
            <hr />
            {contactEmail && <ContactEmailListMui contactEmail={contactEmail} refresh={refresh} />}
            {!contactEmail && <p>No emails</p>}
          </div>
          <div>
            {contactEmail2 && contactEmail2 + ":"}
            <hr />
            {contactEmail2 && <ContactEmailListMui contactEmail={contactEmail2} refresh={refresh} />}
          </div>
          <div>
            {contactEmail3 && contactEmail3 + ":"}
            <hr />
            {contactEmail3 && <ContactEmailListMui contactEmail={contactEmail3} refresh={refresh} />}
          </div>
        </div>
      )}
    </div>
  )
}

export default ContactDetails
