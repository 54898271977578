import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import { GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from "@mui/x-data-grid"
import { randomCreatedDate, randomTraderName, randomInt, randomArrayItem } from "@mui/x-data-grid-generator"
import { Margin } from "@mui/icons-material"
import axios from "axios"
import AppContext from "../../AppContext"
import Models from "../model/Models"
import { FormControl } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import DeleteConfirmationModal from "./DeleteConfirmationModal"
import { useState } from "react"
import EmailIcon from '@mui/icons-material/Email';
import Snackbar from "@mui/material/Snackbar"
import { Alert } from "@mui/material"
import SchemaIcon from '@mui/icons-material/Schema';
import Tooltip from "@mui/material/Tooltip";

const roles = ["Market", "Finance", "Development"]
const randomRole = () => {
  return randomArrayItem(roles)
}

// const initialRows = [
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 25,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 36,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 19,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 28,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 23,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   }
// ]

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props

  const handleClick = () => {
    const id = Math.abs(randomInt())
    setRows(oldRows => [...oldRows, { id, name: "", isNew: true }])
    setRowModesModel(oldModel => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" }
    }))
  }

  return (
    // <GridToolbarContainer>
    //   <Button color="primary" onClick={handleClick}>
    //     Add Lead record
    //   </Button>
    // </GridToolbarContainer>
    <></>
  )
}

export default function ManageEmailSentList() {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState()
  const [severity, setSeverity] = useState()
  //delete 
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [selectedRowId, setSelectedRowId] = useState();


  const deleteReasons = [
    { value: "already sent to buyer/seller", label: "Already sent to buyer/seller" },
    { value: "Tool make model or category is not a match", label: "Tool make model or category is not a match" },
    { value: "Buyer and seller already know each other, DO NOT match any in the future", label: "Buyer and seller already know each other, DO NOT match any in the future" },
    { value: "Buyer or seller already know the source of the tool", label: "Buyer or seller already know the source of the tool" }
  ];
  //
  const [rows, setRows] = React.useState([])
  const [rowModesModel, setRowModesModel] = React.useState({})
  const [modelList, setModelList] = React.useState([])
  const [refresh, setRefresh] = React.useState(false)
  const navigate = useNavigate()

  const token = JSON.parse(localStorage.getItem("token"))

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  React.useEffect(async () => {
    const uniqueNamesMap = new Map()

    let result = await axios.get(`${AppContext.apiUrl}/api/emaildraft`, config)
    

    // Convert the Map values to an array
    const filteredData = result.data.filter(item => item.status === 'sent');


    setRows(removeDuplicates(filteredData))
  }, [refresh])
  let id = state.user.user.id
  let userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  function removeDuplicates(array) {
    // Use a Map to track unique combinations of toEmail and originalListId
    const uniqueMap = new Map();
    
    // Process the array and keep only unique items
    return array.filter(item => {
      if (item.toEmail == null) {
        return false;
      }
      // Trim whitespace from toEmail for consistent comparison
      const email = item.toEmail?.trim();
      // Create a composite key
      const key = `${email}|${item.originalListId}`;
      
      // If this key hasn't been seen before, add it to the map and keep the item
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, true);
        return true;
      }
      
      // Otherwise filter out the duplicate
      return false;
    });
  }
  //call api to get the make list on data load
  React.useEffect(async () => {
    const uniqueNamesMap = new Map()

    let result = await axios.get(`${AppContext.apiUrl}/api/emaildraft`,config)
    

    // Convert the Map values to an array
    const filteredData = result.data?.filter(item => item.status === 'sent');


    setRows(removeDuplicates(filteredData))
  }, [])

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }
  const goToDetails = rowSelected =>{
    //alert(rowSelected.id)
    localStorage.setItem("selectedPipelineDetailId",rowSelected.id)
  
    window.open(`/admin/emailDraftDetail/${rowSelected.id}`,'_blank')
  }

  const handleEditClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteConfirm = (reason) => {
    setSelectedReason(reason);
    setModalOpen(false);
    axios
        .put(`${AppContext.apiUrl}/api/emaildraft/${selectedRowId}`, { id: selectedRowId, deleteReason: reason,status:"deleted" }, config)
        .then(res=>{
          setOpen(true)
          setSeverity("success")
          setMessage("Email draft deleted successfully.")
          // setSelectedStatusValue({value:"deleted",label:"deleted"})
         })
        .catch(e => {
          console.log("something happend when deleting email draft")
        })
    console.log("Deletion confirmed with reason:", reason);
  };

  const handleDeleteClick = id => () => {
    //sset reason option visible and allow user input
    setModalOpen(true);
    setSelectedRowId(id)

    //
    setRows(rows.filter(row => row.id !== id))
  }

  const handleCancelClick = id => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows.find(row => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter(row => row.id !== id))
    }
  }

  const processRowUpdate = newRow => {
    const updatedRow = { ...newRow, isNew: false }
    setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)))
    console.log(newRow.id, newRow.firstName, updatedRow.isNew)

    if (newRow.isNew) {
      axios
        .post(`${AppContext.apiUrl}/api/leads`, { id: newRow.id, firstName: newRow.firstName }, config)
        .then(
          setTimeout(function () {
            // Something you want delayed.
            setRefresh(!refresh)
          }, 3000)
        )
        .catch(e => {
          console.log("something happend when editing makes")
        })
    } else {
      axios
        .put(`${AppContext.apiUrl}/api/leads/${newRow.id}`, { id: newRow.id, firstName: newRow.firstName }, config)
        .then()
        .catch(e => {
          console.log("something happend when editing makes")
        })
    }
    return updatedRow
  }

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel)
  }

  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main"
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />
          ]
        }

        return [
          
          <GridActionsCellItem icon={
            <Tooltip title="Create Pipeline" arrow>
              <SchemaIcon />
            </Tooltip>
          } label="create pipeline" className="textPrimary" onClick={handlePipelineClick(id)} color="inherit" />,
          <GridActionsCellItem icon={<EmailIcon />} label="Send Email" className="textPrimary" onClick={handleEmailClick(id)} color="inherit" />,
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />
        ]
      }
    },
    { field: "id", headerName: "id", width: 80, editable: false },
    { field: "fromEmail", headerName: "From Email", width: 200, editable: true },
    { field: "toEmail", headerName: "To Email", width: 200, editable: true },
    { field: "subject", headerName: "subject", width: 280, editable: true },
    { field: "body", headerName: "body", width: 480, editable: false },
    { field: "contactName", headerName: "Contact Name", width: 180, editable: true },
    { field: "listUrl", headerName: "listUrl", width: 180, editable: false },
    { field: "assigneeId", headerName: "assignee Id", width: 180, editable: false },
    { field: "assigneeName", headerName: "assigneeName", width: 180, editable: false },

    
    { field: "buyerCompanyName", headerName: "buyerCompanyName", width: 180, editable: false },
    { field: "buyerContactName", headerName: "buyerContactName", width: 180, editable: false },
    
    { field: "originalListId", headerName: "ListId", width: 80, editable: false },
    { field: "buyerMake", headerName: "buyer make", width: 80, editable: false,
    valueGetter: (params) => (params.value == null || params.value === "" ? "Unknown" : params.value),

  },
    { field: "buyerModel", headerName: "buyer model", width: 80, editable: false,    valueGetter: (params) => (params.value == null || params.value === "" ? "Unknown" : params.value),
  },
    { field: "buyerType", headerName: "buyer type", width: 80, editable: false },
    
    { field: "sellerCompanyName", headerName: "sellerCompanyName", width: 180, editable: false },
    { field: "sellerContactName", headerName: "sellerContactName", width: 180, editable: false },

    
    { field: "sellerMake", headerName: "seller make", width: 80, editable: false,    valueGetter: (params) => (params.value == null || params.value === "" ? "Unknown" : params.value),
  },
    { field: "sellerModel", headerName: "seller model", width: 80, editable: false,    valueGetter: (params) => (params.value == null || params.value === "" ? "Unknown" : params.value),
  },
    { field: "matchingListId", headerName: "matchListId", width: 80, editable: false },
    { field: "sellType", headerName: "seller type", width: 80, editable: false },
   

    
    { field: "createdAt", headerName: "creation time", width: 180, editable: false },
    // {
    //   field: "model",
    //   headerName: "Model",
    //   width: 420,
    //   editable: true,
    //   type: "singleSelect",
    //   renderCell: params => (
    //     <select
    //       style={{ width: "400px" }}
    //       //value={params.value[0] ? params.value[0].id : ""} // Assuming params.value contains the selected model
    //       value={{ id: params.row.model && params.row.model.length > 0 ? params.row.model[0].id : "", name: params.row.model && params.row.model.length > 0 ? params.row.model[0].name : "" }} // Assuming params.value contains the selected model
    //       onChange={e => {
    //         // Handle the model change here
    //         const newValue = e.target.value
    //         console.log("------params---" + params)
    //         // You may want to call an update function to update your data
    //         // For example: updateModel(params.id, newValue)
    //       }}
    //     >
    //       {params.value &&
    //         params.value.map(modelOption => (
    //           <option key={modelOption ? modelOption.id : ""} id={modelOption ? modelOption.id : ""} value={modelOption ? modelOption.name : ""}>
    //             {modelOption ? modelOption.name : ""}
    //           </option>
    //         ))}
    //     </select>
    //   )
    // },

    
  ]

  const handleEmailClick = id=>() => {

    axios.get(`${AppContext.apiUrl}/api/emaildraft/${id}`, config).then(res => {
      // setData(res.data)

      // setListUrl(res.data.listUrl)
      // setFromEmail(res.data.fromEmail)
      // setToEmail(res.data.toEmail)
      // setSubject(res.data.subject)
      // setContactName(res.data.contactName)
      // setModel(res.data.model)
      // setCategory(res.data.category)
      // setAssigneeName(res.data.assigneeName)
      // setPrompt(res.data.prompt)

      // setSelectedStatusValue({ value: res.data.status, label: res.data.status })
      // setBody(res.data.body)
      
      // setSelectedSalesRepOptionValue({ value: res.data.assigneeId, label: res.data.assigneeName })

      //call api to send email
      const data = {
            //id:params.pipelineId,
            
            from:res.data.fromEmail,
            to:res.data.toEmail,
            subject:res.data.subject,
            listUrl:res.data.listUrl,
            body:res.data.body,
            name:res.data.assigneeName==="Masa"?"Masayuki Son":res.data.assigneeName,
            title:res.data.assigneeName==="Masa"?"Managing Director of ReSochi":"Sales Manager",
            phone:"+1-480-942-2817",
            emailDraftId:id

          }
          

          axios
            .post(`${AppContext.apiUrl}/api/admin/gmailtool/send/`, data, config)
            .then(res => {
              setOpen(true)
              setSeverity("success")
              setMessage("Email sent successfully.")
              // setSelectedStatusValue({label:"sent",value:"sent"})
              //remove from the list, it should go to sent tab
              setRows(rows.filter(row => row.id !== id))
            })
            .catch(e => {
              console.log("something happend when sending email")
              setOpen(true)
              setSeverity("error")
              setMessage("Something went wrong, please contact the admin for help")
            })

      
      
    })
    // e.preventDefault()
    //call pipeline endpoint to save data
    
    
  }

  const handlePipelineClick = id=>() => {

    axios.get(`${AppContext.apiUrl}/api/emaildraft/${id}`, config).then(res => {
      // setData(res.data)

      // setListUrl(res.data.listUrl)
      // setFromEmail(res.data.fromEmail)
      // setToEmail(res.data.toEmail)
      // setSubject(res.data.subject)
      // setContactName(res.data.contactName)
      // setModel(res.data.model)
      // setCategory(res.data.category)
      // setAssigneeName(res.data.assigneeName)
      // setPrompt(res.data.prompt)

      // setSelectedStatusValue({ value: res.data.status, label: res.data.status })
      // setBody(res.data.body)
      
      // setSelectedSalesRepOptionValue({ value: res.data.assigneeId, label: res.data.assigneeName })

      //call api to send email
      // const data = {
      //       //id:params.pipelineId,
            
      //       from:res.data.fromEmail,
      //       to:res.data.toEmail,
      //       subject:res.data.subject,
      //       listUrl:res.data.listUrl,
      //       body:res.data.body,
      //       name:res.data.assigneeName==="Masa"?"Masayuki Son":res.data.assigneeName,
      //       title:res.data.assigneeName==="Masa"?"Managing Director of ReSochi":"Sales Manager",
      //       phone:"+1-480-942-2817",
      //       emailDraftId:id

      //     }
          

      //     axios
      //       .post(`${AppContext.apiUrl}/api/admin/gmailtool/send/`, data, config)
      //       .then(res => {
      //         setOpen(true)
      //         setSeverity("success")
      //         setMessage("Email sent successfully.")
      //         // setSelectedStatusValue({label:"sent",value:"sent"})
      //         //remove from the list, it should go to sent tab
      //         setRows(rows.filter(row => row.id !== id))
      //       })
      //       .catch(e => {
      //         console.log("something happend when sending email")
      //         setOpen(true)
      //         setSeverity("error")
      //         setMessage("Something went wrong, please contact the admin for help")
      //       })

      
      const user = JSON.parse(localStorage.getItem("user"))
    const data = {
      // wantedId: localStorage.getItem("pipelineItem_wanted_id"),
      // listingId: localStorage.getItem("pipelineItem_listing_id")

      // "id": 8136309428191235,
      status: "25% Confirmed Buyer and Seller",
      nextSteps: "follow up",
      equipmentName: [
        res.data.make,
        res.data.model
      ].filter(Boolean).join(" / "),
            buyerRep: "",
      buyerCompany: res.data.buyerCompanyName,
      //  buyerCompanyId: wanttedData.buyerCompany.split('&')[1],
      buyPrice: 0,
      buyerContact: res.data.buyerContactName,
      // // buyerContactId: wanttedData.buyerContact.split('&')[1],
      // // "sellerRep":"Jack",
      sellerCompany: res.data.sellerCompanyName,
      // sellerCompanyId:listingData.sellerCompany.split('&')[1],
      sellerContact: res.data.sellerContactName,
      sellPrice: 0,
      // sellerCompanyId: listingData.sellerCompany.split('&')[1],
      // sellerContactId: listingData.sellerContact.split('&')[1],
      initialWantedAdsId:res.data.originalListId,
      initialListingAdsId:res.data.matchingListId,
      
        assigneeId:user.id,
        assigneeName: user.name  

    }
    axios
      .post(`${AppContext.apiUrl}/api/pipeline`, data, config)
      .then(response => {
        console.log(response.data)
        //navigate(`/admin/pipelineDetail/${response.data.id}`)
        setOpen(true)
              setSeverity("success")
              setMessage("Pipeline created successfully.")
      })
      .catch(error => {
        console.log("error happend: ", error)
        // alert('error happended, try again later')
        //navigate("/login")
        setOpen(true)
              setSeverity("error")
              setMessage("Something went wront, please contact admin for help.")
      })
  }
      
    )
    // e.preventDefault()
    //call pipeline endpoint to save data
    
    
  }

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
        <Alert onClose={() => setOpen(false)} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <DataGrid
      sx={{
        "& .MuiDataGrid-columnSeparator": {
          color: "rgba(0, 0, 0, 0.1)", // Light border for column headers
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)", // Light border for row cells
        }
      }}
    
        rows={rows}
        columns={columns}
        editMode="cell"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel }
        }}
        onRowClick={goToDetails}

      />
      <DeleteConfirmationModal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        onConfirm={handleDeleteConfirm}
        deleteReason={deleteReasons}
      />
    </Box>
  )
}
